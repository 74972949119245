.flex {
    display: flex;
    margin-top: -200px;
  }
  
  .items-center {
    align-items: center;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .h-screen {
    height: 100vh; /* 100% of the viewport height */
  }
  
  .relative {
    position: relative;
  }

  .rectangle2 {
    width: 800px;
    height: 200px;
    background-color: rgb(248, 242, 242);
    border-radius: 50px;
    position: relative; /* Make rectangle the positioned ancestor */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px; /* Add some padding for better text placement */
    box-sizing: border-box;
    text-align: center;
    box-shadow: 0 0 50px #e473f3; /* Add a subtle shadow */
  }
  
  .rectangle2-text {
  /* Space between the text and the button */
    font-size: 1.5rem; /* Adjust as needed */
    color: rgb(49, 49, 49); /* Adjust as needed */
    font-weight:bold;
    margin-bottom: 100px;
    font-family:sans-serif
  }
  
  .email2 {
    width: 550px;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute; /* Absolute positioning within the relative container */
    top: 180px; /* Adjust this value as needed to position the email div */
    left: 50%;    
    transform: translate(-50%,-120%);
    text-align: center;
    color: gray;
  }

  
  .email2 input[type="email"] {
    width: 70%;
    height: 50px;
    padding: 10px;
  
    border: none;
    outline: none;
  }
  
  .button2 {
    padding: 8px 15px;
    background-color: #c03aff;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 10px; /* Adjust as needed */
  }

  @media (min-width: 1300px) {

    .rectangle2 {
      margin-top:-5rem;
      width: 800px;
      height: 200px;
      background-color: rgb(248, 242, 242);
      border-radius: 50px;
      position: relative; /* Make rectangle the positioned ancestor */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px; /* Add some padding for better text placement */
      box-sizing: border-box;
      text-align: center;
      box-shadow: 0 0 50px #e473f3; /* Add a subtle shadow */
    }

  }


  @media (max-width: 768px) {
    .email2 {
      width: 300px;
      background-color: rgb(255, 255, 255);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute; /* Absolute positioning within the relative container */
      top: 130px; /* Adjust this value as needed to position the email div */
      left: 50%;    
      transform: translate(-50%,-120%);
      text-align: center;
      color: gray;
    }


    .flex {
      display: flex;
      margin-top: -250px;
    }
  
    
    .rectangle2-text {
      /* Space between the text and the button */
        font-size: 17px; /* Adjust as needed */
        color: black; /* Adjust as needed */
        font-weight: bolder;
        margin-bottom: 100px;
      }

    .rectangle2 {
      margin-top: -100px;
      width: 350px;
      height: 150px;
      background-color: rgb(248, 242, 242);
      border-radius: 30px;
      position: relative; /* Make rectangle the positioned ancestor */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 7px; /* Add some padding for better text placement */
      box-sizing: border-box;
      text-align: center;
      box-shadow: 0 0 10px #e473f3; /* Add a subtle shadow */
    }

  }
