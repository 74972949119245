.testimonial-container{
  height:400px;
  background-image: linear-gradient(to bottom, #fff1f5, #fff);
  width: 100%;
  margin-top: -7.5rem;
}

.sizea {
 width: 800%;
  
}

.testimonial-container h2 {
  font-size: 30px;
  font-weight:700;
  margin-bottom: 40px;
  background: linear-gradient(135deg, #4a148c, #6a1b9a, #f57c00, #fbc02d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  margin-top: -50px;
  text-align: center;
}

.testimonial-card:not(.testimonial-card--middle)  {
  border-radius: 10px;
  border: 2px solid #ffffff;
  background: whitesmoke;
  width: 50%;
  height: 150px;
  flex-shrink: 0;
  margin-top: 40px;
  padding: 20px; /* Add padding to the card content */
  margin-left: 65px;
  fill-opacity: 10%;
}

.testimonial-card--middle {
  width: 100%;
  height: max-content;
  border-radius: 20px;
  border: 5.765px solid #ffffff;
  background: whitesmoke;
  flex-shrink: 0;
  padding: 20px; /* Add padding to the card content */
  top: 50%;
  left: 50%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.testimonial-card--middle .testimonial-text {
  font-size: 18px;
  margin-top: 10px;
  background: linear-gradient(60deg, #660a98, #056750);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.testimonial-text {
  margin-bottom: 10px; /* Add some spacing between the quote and author */
  font-size: 6px;
  background: linear-gradient(60deg, #660a98, #056750);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  margin-top: 20px;
}

.testimonial-card--middle .testimonial-author {
  font-size: 20px;
}

.testimonial-author {
  font-style: italic;
  font-size: 8px;
  background:rgb(145, 0, 145);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

@media (min-width: 1300px) {
  .testimonial-container{
  height:400px;
  margin-top: -250px;
}

}


@media (max-width: 768px) {
  .testimonial-container{
  height:400px;
  margin-top: -300px;
}

.sizea {
 width: 80%;
  
}



.testimonial-card:not(.testimonial-card--middle)  {
  border-radius: 10px;
  border: 2px solid #FFF;
 
  width: 30%;
  height: 100px;
  flex-shrink: 0;
  margin-top: 40px;
  padding: 20px; /* Add padding to the card content */
  margin-left: 65px;
  fill-opacity: 10%;
}


.testimonial-card--middle {
  border: 8.178px solid #ffffff;

  width: 100%;
  height: 200px;
  border-radius: 20px;
  border: 5.765px solid #FFF;
  flex-shrink: 0;
  padding: 20px; /* Add padding to the card content */
  top: 50%;
  left: 50%;
  margin-bottom: 10px;
  margin-top: 10px;
}

.testimonial-card--middle .testimonial-text {
  font-size: 12px;
}
.testimonial-text {
  margin-bottom: 0px; /* Add some spacing between the quote and author */
  font-size: 5px;
  margin-top: 10px;
 
}

.testimonial-card--middle .testimonial-author {
  font-size: 15px;
}

.testimonial-author {
  font-style: italic;
  font-size: 8px;
}

  
}
