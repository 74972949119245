.pincode-serviceability-page {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: max-content;
  margin-bottom: 200px;
}
.Header-imgp{
 margin-top: 70px;
}

.Header-imgp1{
  width:85%;
  margin-left: -1rem;
  height: 70%;
  margin-top: 5rem;
 }

 .overlay-blockp {
  position: absolute; 
  height: 70px;
  width: 90%;
  background-color: #AC2DE3; /* Semi-transparent black color */
  z-index: 1; /* Set a higher z-index than form container to ensure it's displayed above */
  margin-top: -2rem;
}

.overlay-blockp label1 {
  color: #FFF;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
}



header {
  position: relative;
  width: 100%;
  height: 500px;
  padding: 20px;
  background-color: rgb(251, 251, 251);
  background-size: cover;
  background-position: center;
  margin-top: -1.75rem;
}

.header-content {
  position: absolute;
  top: 50%;
  left: 6%;
  transform: translateY(-50%);
  background-color: rgb(255, 255, 255);
  padding: 20px;
  width: 400px;
  height: 400px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 100px;
  margin-left: 650px;
}


.input-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 2.5rem;
}

.input-group label {
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.23px;
  color: #000000;
  margin-bottom: 10px;
}

.input-group input {
  width: 120%;
  height: 40px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;

}

.check-button {
  width: 100%;
  height: 40px;
  font-family: 'Roboto', sans-serif;
  font-style: bold;
  font-size: 16px;
  margin-top: 30px;
  border-radius: 5px;
  background: linear-gradient(86deg, #A547D9 -26.52%, #5D1586 130.14%);
  color: #fff;
  border: none;
  cursor: pointer;
}

.check-button:hover {
  background: #6a0dad;
}

.pincode-details {
  margin-top: 150px;
  justify-content: center;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: -10px;
  margin-bottom: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin-top: 80px;
  margin-bottom: 30px;
  color: #000000;
}

thead th {
  background-color: #9c42b2;
  font-weight: bold;
  padding: 10px;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  border-bottom: 1px solid #000000;
  color:#fff;
}

tbody td {
  padding: 10px;
  font-family: 'Roboto', sans-serif;
  border-bottom: 1px solid #000000;
  font-size: small;
  font-weight: 500;
}

.statement {
  position: absolute;
  margin-top: -25px;
  margin-bottom: 10px; /* Set the distance from the bottom */
  right: 0; /* Align to the right */
  color: darkviolet; /* Add color to the statement */
  font-size: 9px; /* Set the font size */
  margin-right: 10px;
  font-family: 'Roboto', sans-serif;
  font-style: bold;
  font-weight: 500;
}

.service-message{
  color: #000000;
  margin-left:700px;
  margin-top: 70px;
}


@media (max-width: 768px) {
  .Header-imgp1{
    width:45vh;
    margin-left: -1rem;
    height: 80vh;
    margin-top: 7rem;
   }

   .overlay-blockp{
    margin-left: -3.5px;
    
   }

   header {
    position: relative;
    width: 100%;
    height: 500px;
    padding: 20px;
    background-color: rgb(251, 251, 251);
    background-size: cover;
    background-position: center;
    margin-top: -1.75rem;
  }
  
  .header-content {
    position: absolute;
    top: 80%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Center both vertically and horizontally */
    background-color: rgba(255, 255, 255, 0.8);
    width: 80%; /* Adjust width for smaller screens */
    padding: 15px; /* Adjust padding */
    max-width: 350px; /* Max width to prevent exceeding larger screens */
    height: auto; /* Adjust height to fit content */
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin: 0; /* Remove margin */
  }

  .input-group input {
    width: 100%;
    height: 40px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  .destination-group {
    flex-direction: column;
    align-items: flex-start;
  }

    
  thead th {
    background-color: #953dab;
    font-weight: bold;
    padding: 10px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
    border-bottom: 1px solid #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color:#fff;
  }
  
  tbody td {
    padding: 10px;
    font-family: 'Roboto', sans-serif;
    border-bottom: 1px solid #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .service-message{
    color: #000000;
    margin-left:10px;
    margin-top: 150px;
  }
}

@media (min-width: 1300px) {
  
header {
  position: relative;
  width: 100%;
  height: 500px;
  padding: 20px;
  background-color: rgb(251, 251, 251);
  background-size: cover;
  background-position: center;
  margin-top: -1.75rem;
}

.header-content {
  position: absolute;
  top: 50%;
  left: 6%;
  transform: translateY(-50%);
  background-color: rgb(255, 255, 255);
  padding: 20px;
  width: 450px;
  height: 450px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 150px;
  margin-left: 850px;
}

.input-group input {
  width: 130%;
  height: 40px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.service-message{
  color: #000000;
  margin-left:950px;
  margin-top: 150px;
}

}

