.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: transparent;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  height: 70px;
}

.navbar.scrolled {
  background-color: rgba(255, 255, 255, 0.7);
  transition: background-color 0.3s ease;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.logo {
  color: black;
  font-weight: bold;
  font-size: 2rem;
  margin-left: 20px;
}

.menu-icon {
  display: none;
  font-size: 2rem;
  cursor: pointer;
  margin-right: 20px; /* Add margin to the right to separate it from the logo */
}

.nav-links {
  list-style: none;
  display: flex;
  font-size: 17.5px;
  font-weight: bold;
  align-items: center;
  margin-left: 30px;
}

.nav-links li {
  margin-right: 55px;
}

.nav-links a {
  text-decoration: none;
  color: black;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: purple;
  text-decoration: underline;
}

.buttons {
  display: flex;
  align-items: center;
  margin-left: 0%;
}

.buttons button {
  margin-left: 40px;
  width: 150px;
}

.orange-button {
  background: linear-gradient(to right, orange, #ff8c00);
  color: white;
  border: none;
  border-radius: 10px;
  padding: 12px 30px;
  cursor: pointer;
}



.purple-button {
  background-color: purple;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 12px 30px;
  cursor: pointer;
}

.purple-button:hover {
  background-color: #6a0dad;
}

/* Mobile Styles */
@media (max-width: 768px) {
 
  .menu-icon {
    display: block;
    font-size:large;
    color: black;
    margin-left: -10px;
  }

 

  .purple-button {
    background-color: purple;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 10px 10px;
    cursor: pointer;
    margin-right: -10px;
  
    
  }


  .logo{
    margin-left: -45px;
    margin-top: 10px;
  }

  .orange-buttonm {
    background: linear-gradient(to right, orange, #ff8c00);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 12px 30px;
    cursor: pointer;
  }
 
  .orange-button{
    display: none;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    width: 110%;
    position: absolute;
    top: 80px;
    left: -50px;
    background-color: rgba(255, 241, 245, 0.9);
    transition: max-height 0.3s ease;
    

  }

  .nav-links.open {
    display: flex;
  }

  .nav-links li {
    margin: 15px 0;
    text-align: center;
  }

  .buttons {
    display: flex;
    align-items: center;
  }

  .buttons button {
    margin-left: 10px;
  }

  .orange-button, .purple-button {
    width: auto;
  }
}




