body {
  font-family: "Roboto", sans-serif !important;
}

.sec-icon {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0 auto;
}

.sec-icon::before {
  content: "";
  position: absolute;
  height: 1px;
  left: -70px;
  margin-top: -5.5px;
  top: 60%;
  background: #f5f5f5;
  width: 50px;
}

.sec-icon::after {
  content: "";
  position: absolute;
  height: 1px;
  right: -70px;
  margin-top: -5.5px;
  top: 60%;
  background: #be0a0a;
  width: 50px;
}

.advertisers-service-sec {
  background-color: #fff1f5;
  margin-left:0px ;
}

.advertisers-service-sec span {
  color: rgb(255, 23, 131);
}

.advertisers-service-sec .col {
  padding: 0 1em 1em 1em;
  text-align: center;

}

.advertisers-service-sec .service-card {
  width: 275px;
  height: 70%;
  padding: 2em 1.5em;
  border-radius: 15px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  z-index: 2;
  overflow: hidden;
  background: #fff;
  margin-top: 50px;
  margin-bottom: 50px;
}

.advertisers-service-sec .service-card::after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(#0dcaf0, rgb(255, 23, 131));
  position: absolute;
  left: 0%;
  top: -98%;
  z-index: -2;
  transition: all 0.4s cubic-bezier(0.77, -0.04, 0, 0.99);
}

.advertisers-service-sec h3 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  color: #1f194c;
  margin: 1em 0;
  z-index: 3;
}

.advertisers-service-sec h2 {
  text-transform: capitalize;
  font-weight: 700;
  background-image: linear-gradient(to right, purple, blue) ;
  background-clip: text;
  color: transparent;
  margin: 1em 0;
  z-index: 3;
}

.advertisers-service-sec p {
  color: #575a7b;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.03em;
  z-index: 3;
}

.advertisers-service-sec .icon-wrapper {
  background-color: #2c7bfe;
  position: relative;
  margin: auto;
  font-size: 30px;
  height: 2.5em;
  width: 2.5em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.5s;
  z-index: 3;
}

.advertisers-service-sec .service-card:hover:after {
  top: 0%;
}

.service-card .icon-wrapper {
  background-color: #ffffff;
  color: rgb(255, 23, 131);
}

.advertisers-service-sec .service-card:hover .icon-wrapper {
  color: #0dcaf0;
}

.advertisers-service-sec .service-card:hover h3 {
  color: #ffffff;
}

.advertisers-service-sec .service-card:hover p {
  color: #f0f0f0;
}

@media only screen and (width: 1120px){
  .advertisers-service-sec{
    margin-top: -100px;
    margin-left: -10px;
  }

  .advertisers-service-sec .service-card {
    width: 250px;
    height: max-content;
    padding: 2em 1.5em;
    border-radius: 15px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.5s;
    position: relative;
    z-index: 2;
    overflow: hidden;
    background: #fff;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: -5px;
  }

}


@media (min-width:1300px) {
  .advertisers-service-sec{
    margin-top: -100px;
    margin-left: 20px;
  }

  .advertisers-service-sec .service-card {
    width: 325px;
    height: 70%;
    padding: 2em 1.5em;
    border-radius: 15px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.5s;
    position: relative;
    z-index: 2;
    overflow: hidden;
    background: #fff;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
}
@media (max-width: 768px) {
  .advertisers-service-sec {
    margin-top: -50px;
    text-align: center;
    margin-left: 0;
    padding: 0 10px; /* Add some horizontal padding */
  }

  .advertisers-service-sec .service-card {
    width: 100%; /* Use percentage width for responsiveness */
    max-width: 325px; /* Ensure it doesn't exceed this width */
    height: 300px;
    padding: 2em 1.5em;
    border-radius: 15px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.5s;
    position: relative;
    z-index: 2;
    overflow: hidden;
    background: #fff;
    margin: 0 auto; /* Center align the card */
    margin-bottom: 10px;
  }
}

