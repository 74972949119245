.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }


  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: absolute;
    height: 350px;
  }
  
  .popup-content p {
    margin: 10px 0;
  }
  
  .popup-content .total-cost {
    font-weight:500;
    font-family:sans-serif;
    font-size: 1.2em;
    color: black;
    margin-top: 15px;
    
  }
  
  .popup-image {
    width: 40%;
    height: 250px;
    object-fit: cover;
    margin-top: -350px;
    align-items: end;
    margin-left: 250px;
  }
  
  .text-result1{
    font-size: 2em;
    margin-top: 200px;
    background: linear-gradient(90deg, #760cc9, #ff7b00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: center;
  }
  
  
  .flex {
    display: flex;
  }
  
  .flex-row {
    flex-direction: row;
  }
  
  .center {
    justify-content: center;
    align-items: center;
  }
  
  @media (max-width: 768px) {

    .popup-image {
      width: 40%;
      height: 150px;
      object-fit: cover;
      margin-top: -450px;
      margin-left: 200px;
      transform: translateY(-50px);
    }


  }