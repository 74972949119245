


body,
input,
textarea {
  font-family: "Poppins", sans-serif;
}

.container-mod {
  position: relative;
  height: 700px;
  padding: 2rem;
  background-color:#ffffff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.form-mod {
  width: 100%;
  max-width: 780px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 998;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 60px;
}

.contact-form-mod {
  background-color: #6b0e77;
  position: relative;
}

.circle-mod {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, #d182db);
  position: absolute;
}

.circle-mod.one-mod {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle-mod.two-mod {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}
.input-container-mod input::placeholder {
  color: white; /* Set the placeholder text color to white */
}

.input-container-mod textarea::placeholder {
  color: white; /* Set the placeholder text color to white */
}



.form-containerconthanks-container{
  z-index:999;
  color: #6b0e77;
  font-size: large;
  margin-top: -100px;
}
form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.title-mod {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container-mod {
  position: relative;
  margin: 1rem 0;
}

.input-mod {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 5px;
  transition: 0.3s;
}

textarea.input-mod {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 5px;
  resize: none;
  overflow-y: auto;
}

.input-container-mod label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.input-container-mod.textarea-mod label {
  top: 1rem;
  transform: translateY(0);
}

.btn-mod {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  color: purple;
  line-height: 1;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
  width: 100%;
}

.btn-mod:hover {
  background-color: transparent;
  color: #fff;
}

.input-container-mod span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.input-container-mod span:before,
.input-container-mod span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: #c25dcf;
  top: 50%;
  transform: translateY(-50%);
}

.input-container-mod span:before {
  left: 50%;
}

.input-container-mod span:after {
  right: 50%;
}

.input-container-mod.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.input-container-mod.focus span:before,
.input-container-mod.focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-info-mod {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info-mod .title-mod {
  color: #d852ea;
}

.text-mod {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.information-mod {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.information-mod i {
  color: #d182db, #e1c2e5;
}

.icon {
  width: 28px;
  margin-right: 0.7rem;
}

.social-media-mod {
  padding: 2rem 0 0 0;
}

.social-media-mod p {
  color: #333;
}

.social-icons-mod {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons-mod a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, #b04dbd, #e1c2e5);
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.social-icons-mod a:hover {
  transform: scale(1.05);
}

.contact-info-mod:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #e1c2e5;
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
}

.big-circle-mod {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #d182db, #e1c2e5);
  bottom: 50%;
  right: 50%;
  transform: translate(-20%, 38%);
}

.big-circle-mod:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.square-mod {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

@media (max-width: 850px) {
  .form-mod {
    grid-template-columns: 1fr;
    transform: translate(-10%,0%);
    
  }

  .contact-info-mod:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }


  .contact-form-mod:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .square-mod {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .big-circle-mod {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .text-mod {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media-mod {
    padding: 1.5rem 0 0 0;
  }
  .contact-info-mod{
    display: none;
  }
}

@media (max-width: 480px) {
  .container-mod {
    padding: 1.5rem;
  }

  .contact-info-mod:before {
    display: none;
  }

  .square-mod,
  .big-circle-mod {
    display: none;
  }

  form,
  .contact-info-mod {
    padding: 1.7rem 1.6rem;
  }

  .text-mod,
  .information-mod,
  .social-media-mod p {
    font-size: 0.8rem;
  }

  .title-mod {
    font-size: 1.15rem;
  }

  .social-icons-mod a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .icon {
    width: 23px;
  }

  .input-mod {
    padding: 0.45rem 1.2rem;
  }

  .btn-mod {
    padding: 0.45rem 1.2rem;
  }
}
