.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px;
}

.about-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0;
  width: 100%;
}

.about-section.reverse {
  flex-direction: row-reverse;
}

.about-image {
  width: 450px;
  height: 250px;
  margin: 20px;
}

.about-text {
  max-width: 600px;
  margin: 20px;
  text-align: left;
}

.about-text h2 {
  margin-bottom: 10px;
  font-size: 24px;
  background: linear-gradient(135deg, #ff8000, #c607f1, #edc100a7, #e00505);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.about-text p {
  font-size: 16px;
  line-height: 1.6;
  background: linear-gradient(135deg, #4a148c, #6a1b9a, #f57c00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

/* Responsive Styles */

/* For tablets and smaller devices */
@media (max-width: 768px) {
  .about-section {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .about-image {
    width: 100%;
    height: auto;
    margin: 20px 0;
  }

  .about-text {
    max-width: 100%;
    margin: 20px 0;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .about-container {
    padding: 20px;
  }

  .about-text h2 {
    font-size: 20px;
  }

  .about-text p {
    font-size: 14px;
  }
}

