@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@400;600;700&display=swap");

* {
 box-sizing: border-box;
 padding: 0;
 margin: 0;
}

:root {
 --header-height: 3.5rem;
 --first-color: hsl(28, 88%, 62%);
 --title-color: hsl(0, 0%, 95%);
 --text-color: hsl(0, 0%, 75%);
 --body-color: hsl(0, 0%, 6%);
 --body-font: "Montserrat", sans-serif;
 --second-font: "Dancing Script", cursive;
 --h2-font-size: 1.25rem;
 --small-font-size: 0.813rem;
 --font-semi-bold: 600;
 --z-tooltip: 10;
 --z-fixed: 100;
}

body {
 background-color: var(--body-color);
 color: var(--text-color);
 font-family: var(--body-font);
}

ul {
 list-style: none;
}

a {
 text-decoration: none;
}

@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap");
.body {
  font-family: "Archivo", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}
.flowchart {
  display: inline-flex;
  justify-items: center;
  align-items: center;
  margin-top: 50px; 
}

